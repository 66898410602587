import React from "react";
import bgImage from "../util/img/DSC_6049.jpg";
import { useNavigate } from "react-router-dom";

const NewThetaPage: React.FC = () => {
  const navigate = useNavigate();

  const sectionStyle = "p-8 pl-[20%] pr-[20%] bg-white";

  const handleClick = () => {
    navigate("/reservation");
  };
  return (
    <div
      className="top-0 pt-24 w-full min-h-screen bg-cover bg-center bg-fixed flex flex-col justify-center"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <section className="p-16 pl-[20%] pr-[20%] bg-white ">
        <h2 className="text-2xl font-bold pb-2 uppercase">
          Co je ThetaHealing?
        </h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>
        <p>
          - ThetaHealing je meditační technika a duchovní filozofie, která vám
          umožňuje přistupovat k podvědomým vzorcům a měnit je. Tato metoda
          využívá theta mozkové vlny, které se obvykle vyskytují ve stavech
          hluboké relaxace nebo meditace. Pomocí ThetaHealingu můžete léčit
          fyzické, emocionální a duchovní problémy, přičemž hlavním cílem je
          dosažení pozitivních změn v životě.
        </p>
      </section>
      <section className={sectionStyle}>
        <h2 className="text-2xl font-bold pb-2 uppercase">
          Jak ThetaHealing funguje?
        </h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        <p>
          ThetaHealing kombinuje techniky meditace a energetického léčení, které
          pomáhají jednotlivcům propojit se s božským zdrojem. Proces začíná
          stavem hluboké meditace, kdy se praktikující nachází v theta mozkových
          vlnách. V tomto stavu jsou praktikující schopni identifikovat a změnit
          omezující přesvědčení a emoce, které jsou uloženy v podvědomí.
          Léčitelé se zaměřují na odstranění negativních energetických bloků,
          což umožňuje přirozenému procesu léčení těla a mysli.
        </p>
      </section>
      <section className={sectionStyle}>
        <h2 className="text-2xl font-bold pb-2 uppercase">
          Výhody ThetaHealingu
        </h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        <p>
          ThetaHealing nabízí řadu výhod, včetně:
          <ul className="list-disc list-inside pl-4 mt-2">
            <li>
              Zlepšení fyzického zdraví: ThetaHealing může pomoci při léčení
              různých fyzických onemocnění a bolestí.
            </li>
            <li>
              Emocionální rovnováha: Tato technika může přinést emocionální
              uzdravení a harmonii.
            </li>
            <li>
              Duchovní růst: ThetaHealing podporuje duchovní probuzení a
              propojení s vyšším já.
            </li>
            <li>
              Osobní transformace: Praktikování ThetaHealingu může vést k
              hlubokým osobním změnám a růstu, přinášejíc pozitivní změny ve
              všech oblastech života.
            </li>
          </ul>
        </p>
      </section>
      <section className={sectionStyle}>
        <h2 className="text-2xl font-bold pb-2 uppercase">
          Historie ThetaHealingu
        </h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        <p>
          ThetaHealing byla vyvinuta v roce 1995 Viannou Stibal, která hledala
          způsoby, jak vyléčit svou vlastní nemoc. Po několika experimentech s
          různými metodami léčení a meditace, objevila sílu theta mozkových vln.
          Své poznatky a zkušenosti začala sdílet s ostatními, což vedlo k
          vytvoření metody ThetaHealing. Dnes je ThetaHealing praktikována po
          celém světě a nabízí různé kurzy a semináře pro zájemce o hlubší
          pochopení a praktickou aplikaci této techniky.
        </p>
      </section>
      <section className={sectionStyle}>
        <h2 className="text-2xl font-bold pb-2 uppercase">
          ThetaHealing a věda
        </h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        <p>
          Zatímco ThetaHealing je často považována za duchovní nebo alternativní
          léčebnou metodu, existuje rostoucí zájem o její vědecké zkoumání.
          Některé studie naznačují, že změna mozkových vln může mít pozitivní
          vliv na tělo a mysl, což podporuje účinnost meditace a dalších
          podobných technik. Výzkum v této oblasti je stále v počátcích, ale
          mnoho lidí hlásí osobní zkušenosti s pozitivními výsledky při
          používání ThetaHealingu.
        </p>
      </section>
      <section className={sectionStyle}>
        <h2 className="text-2xl font-bold pb-2 uppercase">
          Jak začít s ThetaHealingem
        </h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        <p>
          Pokud máte zájem o ThetaHealing, existuje několik způsobů, jak začít.
          Můžete se zúčastnit semináře nebo kurzu, kde vás naučí základní
          techniky a principy ThetaHealingu. Existují také knihy a online
          zdroje, které poskytují podrobné informace a návody. Důležité je najít
          zkušeného a certifikovaného praktikujícího, který vás může vést a
          podpořit ve vašem osobním rozvoji a léčení.
        </p>
      </section>
      <section className={"p-8 pb-20 pl-[20%] pr-[20%] bg-white mb-20"}>
        <h2 className="text-2xl font-bold mb-8">
          Pokud tě cokoliv z toho zaujalo domluv si se mnou schůzku.
        </h2>
        <p>
          <button
            type="submit"
            onClick={handleClick}
            className="bg-violet-500 text-white py-3 px-6 pt-2 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 justify-end"
          >
            Rezervace schůzky
          </button>
        </p>
      </section>
    </div>
  );
};

export default NewThetaPage;
