import React from "react";
import bgImage from "../util/img/DSC_6049.jpg";
import { useNavigate } from "react-router-dom";

const WorkInProgressPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/newhome");
  };
  return (
    <div
      className="w-screen h-screen bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div
        className="text-gray-600 text-center p-4 bg-white/40 rounded-xl cursor-pointer"
        onClick={handleClick}
      >
        <h1 className="text-5xl  mb-4 font-sans" onClick={handleClick}>
          CESTA SVĚTLA
        </h1>

        <p className="text-xl text-pretty font-mono">
          Rozsvěcíme pro vás něco nového, mějte prosím strpení.
        </p>
      </div>
    </div>
  );
};

export default WorkInProgressPage;
