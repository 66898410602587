import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import GDPRModal from "./GDPRModal";
import CookiesModal from "./CookiesModal";

const fontStyle =
  "text-xs lg:text-sm text-white font-['MyCustomFont2'] align-text-bottom tracking-tighter hover:shadow-ms transition-all duration-300 ease-in-out";

const NewFooter: React.FC = () => {
  const [isGDPROpen, setIsGDPROpen] = useState(false);
  const [isCookiesOpen, setIsCookiesOpen] = useState(false);

  const handleGDPROpen = () => setIsGDPROpen(true);
  const handleGDPRClose = () => setIsGDPROpen(false);
  const handleGDPRAccept = () => {
    setIsGDPROpen(false);
    // Handle GDPR acceptance logic
  };

  const handleCookiesOpen = () => setIsCookiesOpen(true);
  const handleCookiesClose = () => setIsCookiesOpen(false);
  const handleCookiesAccept = () => {
    setIsCookiesOpen(false);
    // Handle Cookies acceptance logic
  };
  return (
    <footer className="relative text- -mb-16 bottom-16 left-0 w-full h-16 bg-gradient-to-t from-white/60 to-transparent">
      <GDPRModal
        isOpen={isGDPROpen}
        onClose={handleGDPRClose}
        onAccept={handleGDPRAccept}
      />
      <CookiesModal
        isOpen={isCookiesOpen}
        onClose={handleCookiesClose}
        onAccept={handleCookiesAccept}
      />
      <div className="flex w-full flex-row justify-between pl-4 lg:pl-10 pt-4 lg:pt-1">
        <div className="flex flex-row space-x-2 pt-1">
          <NavLink to={"#"} className={fontStyle} onClick={handleCookiesOpen}>
            {"ZÁSADY COOKIES"}
          </NavLink>
          <span className={fontStyle}>{"|"}</span>
          <NavLink to={"#"} className={fontStyle} onClick={handleGDPROpen}>
            {"OCHRANA OSOBNÍCH ÚDAJŮ"}
          </NavLink>
        </div>
        <div className="flex space-x-4 pr-4 lg:pr-10 justify-end text-white">
          <a
            href="https://www.instagram.com/cestasvetla?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
            rel="noopener noreferrer"
            className="transition-all duration-300 ease-in-out transform hover:scale-125 hover:shadow-inner"
          >
            <FontAwesomeIcon icon={["fab", "instagram"]} size="xl" />
          </a>
          <a
            href="https://www.instagram.com/cestasvetla?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
            rel="noopener noreferrer"
            className="transition-all duration-300 ease-in-out transform hover:scale-125 hover:shadow-inner"
          >
            <FontAwesomeIcon icon={["fab", "facebook"]} size="xl" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;
