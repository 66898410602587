import React, { useRef } from "react";
import bgImage from "../util/img/DSC_6049.jpg";
import InstagramCarousel from "../components/InstagramCarousel";

const feedbacks = [
  {
    image: "https://via.placeholder.com/100", // replace with actual image URLs
    name: "John Doe",
    text: "This is a great service! I had an amazing experience.",
  },
  {
    image: "https://via.placeholder.com/100",
    name: "Jane Smith",
    text: "I highly recommend this to everyone. Very professional!",
  },
  {
    image: "https://via.placeholder.com/100",
    name: "Alice Johnson",
    text: "Amazing! I will definitely come back again. The quality is unmatched.",
  },
  {
    image: "https://via.placeholder.com/100", // replace with actual image URLs
    name: "John Doe",
    text: "This is a great service! I had an amazing experience.",
  },
  {
    image: "https://via.placeholder.com/100",
    name: "Jane Smith",
    text: "I highly recommend this to everyone. Very professional!I highly recommend this to everyone. Very professional!I highly recommend this to everyone. Very professional!I highly recommend this to everyone. Very professional!I highly recommend this to everyone. Very professional!I highly recommend this to everyone. Very professional!",
  },
  {
    image: "https://via.placeholder.com/100",
    name: "Alice Johnson",
    text: "Amazing! I will definitely come back again. The quality is unmatched.",
  },
  // Add more feedback items as needed
];

const NewFeedbackPage: React.FC = () => {
  const feedbackRefs = useRef<(HTMLDivElement | null)[]>([]);

  return (
    <div
      className="top-0 pt-24 w-full min-h-screen bg-cover bg-center bg-fixed flex flex-col items-center justify-center"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="top-24 p-8 pl-[10%] pr-[10%] bg-white/90 z-10 w-full xl:w-2/3">
        <h2 className="text-base sm:text-xl pb-2 text-center font-bold text-gray-800 font-['MyCustomFont2'] uppercase">
          Milá slova od vás
        </h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>
        <p className="text-xs sm:text-base font-bold text-gray-800">
          Recenze od Vás, kteří se mnou prošli Theta Healingem nebo jinou formou
          léčení nebo meditace. Velice Vám děkuji za vaše ohlasy a slova.
        </p>
      </div>

      {/* Scrollable Page Content */}
      <div className="relative z-0 px-6 mb-24 bg-white/90 w-full xl:w-2/3">
        <div className="max-w-4xl pb-10 mx-auto bg-transparent">
          {/* Feedback Section */}

          {feedbacks.map((feedback, index) => (
            <div
              key={index}
              className="flex items-start space-x-8 p-4 mb-5 m-3 border-b bg-purple-400/30 shadow-lg shadow-purple-600 rounded-3xl border-gray-300 transition-opacity duration-500 ease-in-out"
              ref={(el) => (feedbackRefs.current[index] = el)} // Keep references to each item
            >
              {/* Round Image */}
              <img
                src={feedback.image}
                alt={feedback.name}
                className="w-24 h-24 rounded-full object-cover shadow-lg shadow-purple-600"
              />
              {/* Feedback Text */}
              <div>
                <h3 className="text-xl font-bold text-gray-800">
                  {feedback.name}
                </h3>
                <p className="text-gray-600 mt-2">{feedback.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewFeedbackPage;
