export interface Comment {
    id: number;
    author: string;
    content: string;
  }
  
  export interface Article {
    id: number;
    title: string;
    summary: string;
    content: string[];
    image: string;
    comments: Comment[];
  }
  
  export const articles: Article[] = [
    {
      id: 1,
      title: 'The Role of Creative Graphic Designers in Modern Marketing',
      summary: 'Explore the impact of creative graphic designers on modern marketing strategies and how they contribute to brand success.',
      content: [
        'In today\'s fast-paced digital world, the role of creative graphic designers has become more critical than ever. They are the visionaries who transform ideas into visually compelling messages that capture the attention of audiences and drive engagement. From branding to advertising, their contributions are essential to the success of any marketing strategy.',
        
        'Creative graphic designers possess a unique blend of artistic talent and technical skills. They are proficient in using design software like Adobe Photoshop, Illustrator, and InDesign to create stunning visuals. But their expertise goes beyond mere software proficiency. They understand the principles of design, including color theory, typography, and composition, which allows them to create aesthetically pleasing and effective designs.',
        
        'One of the primary responsibilities of graphic designers is to develop a brand\'s visual identity. This involves creating logos, color schemes, and typography that reflect the brand\'s personality and values. A well-designed visual identity helps establish brand recognition and builds trust with the audience. It is the visual representation of a brand\'s promise and plays a crucial role in differentiating it from competitors.',
        
        'In addition to branding, graphic designers are instrumental in creating marketing materials that promote products and services. This includes designing brochures, flyers, social media graphics, and website elements. Their designs need to be not only visually appealing but also aligned with the overall marketing strategy. Effective design can significantly enhance the impact of marketing campaigns, making them more memorable and engaging.',
        
        '![Graphic Design](https://via.placeholder.com/600)',
        
        'The digital landscape is constantly evolving, and graphic designers must stay updated with the latest trends and technologies. This continuous learning process enables them to incorporate new techniques and tools into their work, ensuring that their designs remain fresh and relevant. From responsive web design to interactive graphics, staying ahead of the curve is essential for delivering innovative solutions.',
        
        'Collaboration is another key aspect of a graphic designer\'s role. They often work closely with marketing teams, copywriters, and other creative professionals to bring ideas to life. Effective communication and teamwork are vital to ensuring that the final design aligns with the project\'s objectives and resonates with the target audience.',
        
        'In conclusion, creative graphic designers play a pivotal role in modern marketing. Their ability to craft visually compelling messages helps brands connect with their audiences and achieve their marketing goals. As the digital landscape continues to evolve, the demand for skilled graphic designers will only increase, making them indispensable assets to any marketing team.'
      ],
      image: 'https://via.placeholder.com/600',
      comments: [
        { id: 1, author: 'John Doe', content: 'Great article!' },
        { id: 2, author: 'Jane Doe', content: 'Very informative.' },
      ],
    },
    {
      id: 2,
      title: 'Second Blog Post',
      summary: 'This is the summary of the second blog post.',
      content: ['This is the content of the second blog post.'],
      image: 'https://via.placeholder.com/600',
      comments: [
        { id: 1, author: 'Alice', content: 'Nice read.' },
        { id: 2, author: 'Bob', content: 'I learned a lot.' },
      ],
    },
    {
      id: 3,
      title: 'Third Blog Post',
      summary: 'This is the summary of the third blog post.',
      content: ['This is the content of the third blog post.'],
      image: 'https://via.placeholder.com/600',
      comments: [
        { id: 1, author: 'Charlie', content: 'Thanks for sharing!' },
        { id: 2, author: 'Dave', content: 'Well written.' },
      ],
    },
  ];
  