import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { articles } from "../data/articles";

const Article: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const article = articles.find(
    (article) => article.id === parseInt(id || "0", 10)
  );

  const [commentAuthor, setCommentAuthor] = useState("");
  const [commentContent, setCommentContent] = useState("");
  const [comments, setComments] = useState(article ? article.comments : []);

  const handleAddComment = () => {
    if (commentAuthor && commentContent) {
      const newComment = {
        id: comments.length + 1,
        author: commentAuthor,
        content: commentContent,
      };
      setComments([...comments, newComment]);
      setCommentAuthor("");
      setCommentContent("");
    }
  };

  if (!article) {
    return <div className="container mx-auto p-4">Article not found</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">{article.title}</h1>
      <img
        src={article.image}
        alt={article.title}
        className="mb-6 w-full h-64 object-cover"
      />
      {article.content.map((paragraph, index) =>
        paragraph.startsWith("![Graphic Design]") ? (
          <img
            key={index}
            src={paragraph.replace("![Graphic Design](", "").replace(")", "")}
            alt="Graphic Design"
            className="mb-6 w-full h-64 object-cover"
          />
        ) : (
          <p key={index} className="mb-6">
            {paragraph}
          </p>
        )
      )}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Comments</h2>
        <ul className="space-y-4 mb-4">
          {comments.map((comment) => (
            <li key={comment.id} className="p-4 border rounded shadow">
              <p className="font-bold">{comment.author}</p>
              <p>{comment.content}</p>
            </li>
          ))}
        </ul>
        <div className="flex flex-col space-y-4">
          <input
            type="text"
            placeholder="Your name"
            value={commentAuthor}
            onChange={(e) => setCommentAuthor(e.target.value)}
            className="p-2 border rounded"
          />
          <textarea
            placeholder="Your comment"
            value={commentContent}
            onChange={(e) => setCommentContent(e.target.value)}
            className="p-2 border rounded"
          />
          <button
            onClick={handleAddComment}
            className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition"
          >
            Add Comment
          </button>
        </div>
      </div>
    </div>
  );
};

export default Article;
