import React from "react";

interface GDPRModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}

const GDPRModal: React.FC<GDPRModalProps> = ({ isOpen, onClose, onAccept }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg max-w-lg w-full">
        <h2 className="text-2xl font-bold mb-4">Souhlas s GDPR</h2>
        <p className="mb-4">
          GDPR (Obecné nařízení o ochraně osobních údajů) je nařízení v právu EU
          o ochraně dat a soukromí v Evropské unii a Evropském hospodářském
          prostoru. GDPR také řeší předávání osobních údajů mimo EU a EHP
          oblasti.
        </p>
        <p className="mb-4">
          Hlavním cílem GDPR je dát jednotlivcům kontrolu nad jejich osobními
          údaji a zjednodušit regulační prostředí pro mezinárodní obchod
          sjednocením nařízení v rámci EU.
        </p>
        <p className="mb-4">
          Organizace, které shromažďují údaje o občanech v zemích Evropské unie,
          musí dodržovat přísná nová pravidla týkající se ochrany údajů
          zákazníků. Nařízení nařizuje, aby organizace chránily osobní údaje a
          soukromí občanů EU pro transakce, ke kterým dochází v členských
          státech EU.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white py-2 px-4 rounded"
          >
            Zavřít
          </button>
          <button
            onClick={onAccept}
            className="bg-blue-500 text-white py-2 px-4 rounded"
          >
            Souhlasím
          </button>
        </div>
      </div>
    </div>
  );
};

export default GDPRModal;
