import React from "react";
import ContactForm from "../components/ContactForm";
import bgImage from "../util/img/DSC_6049.jpg";
import { useNavigate } from "react-router-dom";

const Contact: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/reservation");
  };

  return (
    <div
      className="w-screen min-h-screen bg-cover bg-center bg-fixed flex flex-col justify-center"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="flex flex-col xl:flex-row w-full sm:w-3/5 h-auto xl:h-2/3 pt-24 xl:pt-0 mb-14 xl:mb-0 sm:ml-20 justify-center align-middle items-stretch bg-white/80 ">
        <section className="flex flex-col w-full xl:w-1/3 p-5 justify-start items-center ">
          <h2 className="text-lg sm:text-lg pt-4 font-['MyCustomFont2'] uppercase">
            {" "}
            Děkuji za váš zájem!
          </h2>
          <div className="h-px w-full bg-purple-500 mb-2"></div>
          <div className="flex flex-row w-full pt-4 pr-4 text-xs sm:text-base text-justify max-w-xs">
            V případě dotazů nebo zájmu o navázání spolupráce mě kontaktujte
            přes formulář, kde vám co nejdříve odpovím, nebo mi napište přes
            email, Instagram nebo Facebook.
          </div>
          <div className="flex flex-row w-full pt-4 pr-4 text-xs sm:text-base text-justify max-w-xs">
            Nebo si se mnou rovnou rezervujte online schůzku v kalendáři.
          </div>
          <div className="flex pt-6 -ml-2 w-full justify-center">
            <button
              type="submit"
              onClick={handleClick}
              className="bg-violet-500 text-white text-xs sm:text-base py-3 px-6 pt-2 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 justify-end"
            >
              Rezervace schůzky
            </button>
          </div>
        </section>
        <ContactForm show />
      </div>
    </div>
  );
};

export default Contact;
