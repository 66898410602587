import React from "react";
import bgImage from "../util/img/DSC_6049.jpg";
import { useNavigate } from "react-router-dom";
import img1 from "../util/img/DSC_5995.jpg";
import img2 from "../util/img/DSC_6032.jpg";
import img3 from "../util/img/DSC_6008.jpg";

const feedbacks = [
  {
    image: "https://via.placeholder.com/100", // replace with actual image URLs
    name: "John Doe",
    text: "This is a great service! I had an amazing experience.",
  },
  {
    image: "https://via.placeholder.com/100",
    name: "Jane Smith",
    text: "I highly recommend this to everyone. Very professional!",
  },
  {
    image: "https://via.placeholder.com/100",
    name: "Alice Johnson",
    text: "Amazing! I will definitely come back again. The quality is unmatched.",
  },
  // Add more feedback items as needed
];

const NewHomePage: React.FC = () => {
  const sectionStyle = "p-8 pl-[20%] pr-[20%] bg-white";

  const navigate = useNavigate();

  const handleClickTheta = () => {
    navigate("/theta");
  };
  const handleClickReservation = () => {
    navigate("/reservation");
  };

  const handleClick = () => {
    navigate("/contact");
  };

  const handleClickFeedback = () => {
    navigate("/feedback");
  };
  const aboutmeClick = () => {
    navigate("/about");
  };

  return (
    <div
      className="top-0 pt-24  w-full min-h-screen bg-cover bg-center bg-fixed flex flex-col justify-center"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <section className="flex flex-col p-8 pt-20 lg:pb-32 bg-transparent min-h-screen items-end md:items-center justify-between">
        <div className="flex flex-col md:flex-row w-full items-center justify-center md:justify-start md:pl-32 md:mt-24">
          <div
            className="aspect-square h-full bg-transparent text-center p-4  mb-8 md:mb-0 cursor-pointer"
            onClick={handleClickTheta}
          >
            <div className="aspect-square md:w-[18vw] bg-white/40 hover:transition-all duration-700 ease-in-out hover:translate-x-1 hover:-translate-y-1">
              <div className=" flex flex-col items-center justify-center aspect-square  translate-x-6 -translate-y-6 bg-white/40 hover:transition-all duration-700 ease-in-out hover:translate-x-4 hover:-translate-y-4">
                <h3 className="text-xl text-gray-800 font-['MyCustomFont2'] text-responsive">
                  METODA
                </h3>
                <h3 className="text-2xl md:text-3xl w-5/6 text-gray-800 font-['MyCustomFont2']">
                  THETA HEALING
                </h3>
              </div>
            </div>
          </div>

          <div
            className="aspect-square h-full bg-transparent text-center p-4 md:ml-20 cursor-pointer"
            onClick={handleClickReservation}
          >
            <div className="aspect-square  md:w-[18vw] bg-white/40 hover:transition-all duration-700 ease-in-out hover:translate-x-1 hover:-translate-y-1">
              <div className=" flex flex-col items-center justify-center aspect-square  translate-x-6 -translate-y-6 bg-white/40 hover:transition-all duration-700 ease-in-out hover:translate-x-4 hover:-translate-y-4">
                <h3 className="text-xl  text-gray-800 font-['MyCustomFont2'] text-responsive">
                  PRO VÁS
                </h3>
                <h3 className="text-2xl md:text-3xl w-5/6 text-gray-800 font-['MyCustomFont2']">
                  TERAPIE ONLINE
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full md:w-3/5 lg:w-2/5 h-1/6 bg-white/60 mb-20 lg:mb-16 lg:mt-20 justify-center items-center text-center">
          <h1 className="cursor-default text-responsive font-medium font-['MyCustomFont3']">
            {" "}
            CESTA SVĚTLA
          </h1>
          <h1 className="cursor-default absolute text-responsive font-medium  ml-1 mt-1 font-['MyCustomFont3']">
            {" "}
            CESTA SVĚTLA
          </h1>
        </div>
      </section>

      <section className={sectionStyle}>
        <div className="flex flex-col xl:flex-row w-full">
          <img
            src={img1}
            alt="Logo"
            className="h-full max-w-96 shadow-lg shadow-purple-600 mb-8 xl:mb-0"
          />
          <div className="flex-col xl:pl-32">
            <h2 className="text-2xl font-bold pb-2 uppercase">O mně</h2>
            <div className="h-px w-full bg-purple-500 mb-2"></div>

            <p>
              Moje cesta začala v roce [Rok], kdy jsem se rozhodl/a věnovat
              [Vaše Profese/Hobby]. Od té doby jsem prošel/a různými rolemi a
              projekty, které mi umožnily rozvíjet své dovednosti a získat cenné
              zkušenosti. Každý krok na mé cestě mě naučil něco nového a přinesl
              mi mnoho inspirace. Moje cesta začala v roce [Rok], kdy jsem se
              rozhodl/a věnovat [Vaše Profese/Hobby]. Od té doby jsem prošel/a
              různými rolemi a projekty, které mi umožnily rozvíjet své
              dovednosti a získat cenné zkušenosti. Každý krok na mé cestě mě
              naučil něco nového a přinesl mi mnoho inspirace.
            </p>

            <h2 className="text-2xl font-bold pb-2 uppercase pt-8">
              Moje cesta
            </h2>
            <div className="h-px w-full bg-purple-500 mb-2"></div>

            <p>
              Moje cesta začala v roce [Rok], kdy jsem se rozhodl/a věnovat
              [Vaše Profese/Hobby]. Od té doby jsem prošel/a různými rolemi a
              projekty, které mi umožnily rozvíjet své dovednosti a získat cenné
              zkušenosti. Každý krok na mé cestě mě naučil něco nového a přinesl
              mi mnoho inspirace.
            </p>
            <p>
              <button
                type="submit"
                onClick={aboutmeClick}
                className="bg-violet-500 text-white py-3 px-6 mt-6 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 justify-end"
              >
                Více o mě a mé cestě
              </button>
            </p>
          </div>
        </div>
      </section>
      <section className={sectionStyle}>
        <h2 className="text-2xl font-bold pb-2 uppercase">
          Co je ThetaHealing
        </h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        <p>
          Během své kariéry jsem pracoval/a na různých projektech, které
          zahrnovaly [krátký popis projektů nebo pracovních zkušeností]. Měl/a
          jsem příležitost spolupracovat s úžasnými lidmi a týmy, kteří mě
          motivovali a inspirovali. Moje práce mě baví a naplňuje a vždy se
          snažím dosáhnout nejlepších výsledků.
        </p>
      </section>
      <section className="p-8 pl-[20%] pr-[20%] bg-white items-center justify-center">
        <img
          src={img2}
          alt="Logo"
          className="h-full md:max-w-xl shadow-lg shadow-purple-600 mb-8 xl:mb-0"
        />
      </section>
      <section className={sectionStyle}>
        <p>
          S čím vám může pomoci:
          <ul className="list-disc list-inside pl-4 mt-2">
            <li>
              Zlepšení fyzického zdraví: ThetaHealing může pomoci při léčení
              různých fyzických onemocnění a bolestí.
            </li>
            <li>
              Emocionální rovnováha: Tato technika může přinést emocionální
              uzdravení a harmonii.
            </li>
            <li>
              Duchovní růst: ThetaHealing podporuje duchovní probuzení a
              propojení s vyšším já.
            </li>
            <li>
              Osobní transformace: Praktikování ThetaHealingu může vést k
              hlubokým osobním změnám a růstu, přinášejíc pozitivní změny ve
              všech oblastech života.
            </li>
          </ul>
        </p>
        <p>
          <button
            type="submit"
            onClick={handleClickTheta}
            className="bg-violet-500 text-white py-3 px-6 mt-6 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 justify-end"
          >
            Více o ThetaHealingu
          </button>
        </p>
      </section>
      <section className={sectionStyle}>
        <div className="flex flex-col xl:flex-row w-full">
          <div className="flex-col xl:pr-32">
            <h2 className="text-2xl font-bold pb-2 uppercase">
              S čím vám mohu pomoci
            </h2>
            <div className="h-px w-full bg-purple-500 mb-2"></div>

            <p>
              Kromě své práce se také věnuji [vaše zájmy nebo koníčky]. Rád/a
              [krátký popis vašich zájmů], což mi pomáhá udržovat rovnováhu mezi
              pracovním a osobním životem. Tyto aktivity mi přinášejí radost a
              inspiraci a často mě vedou k novým nápadům a projektům. Kromě své
              práce se také věnuji [vaše zájmy nebo koníčky]. Rád/a [krátký
              popis vašich zájmů], což mi pomáhá udržovat rovnováhu mezi
              pracovním a osobním životem. Tyto aktivity mi přinášejí radost a
              inspiraci a často mě vedou k novým nápadům a projektům.
            </p>

            <p>
              <button
                type="submit"
                onClick={handleClickReservation}
                className="bg-violet-500 text-white py-3 px-6 mt-6 mb-6 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 justify-end"
              >
                Rezervace schůzky
              </button>
            </p>
          </div>
          <img
            src={img3}
            alt="Logo"
            className="h-full max-w-96 shadow-lg shadow-purple-600 mb-8 xl:mb-0"
          />
        </div>
      </section>
      <section className={sectionStyle}>
        <h2 className="text-2xl font-bold pb-2 uppercase">
          Vybrané recenze klientů
        </h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        {feedbacks.map((feedback, index) => (
          <div
            key={index}
            className="flex items-start space-x-1 p-4 mb-5 m-3 border-b bg-purple-400/30 shadow-lg shadow-purple-600 rounded-3xl border-gray-300 transition-opacity duration-500 ease-in-out"
          >
            {/* Round Image */}
            <img
              src={feedback.image}
              alt={feedback.name}
              className="w-24 h-24 rounded-full object-cover shadow-lg shadow-purple-600"
            />
            {/* Feedback Text */}
            <div>
              <h3 className="text-xl font-bold text-gray-800">
                {feedback.name}
              </h3>
              <p className="text-gray-600 mt-2">{feedback.text}</p>
            </div>
          </div>
        ))}

        <p>
          <button
            type="submit"
            onClick={handleClickFeedback}
            className="bg-violet-500 text-white py-3 px-6 mt-6 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 justify-end"
          >
            Více Recenzí
          </button>
        </p>
      </section>
      <section className={sectionStyle}>
        <h2 className="text-2xl font-bold pb-2 uppercase">Kontakt</h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        <p>
          Pokud máte zájem se se mnou spojit nebo se dozvědět více o mé práci,
          neváhejte mě kontaktovat. Rád/a odpovím na vaše otázky a proberu
          možnosti spolupráce. Můžete mě kontaktovat na [vaše kontaktní
          informace].
        </p>
        <p>
          <a
            href="mailto:your-email@example.com"
            className="flex items-center pt-6"
          >
            <span className="text-xl">📧</span> {/* Email Icon */}
            <span className="ml-2">your-email@example.com</span>
          </a>
        </p>

        <p>
          <a href="tel:+1234567890" className="flex items-center">
            <span className="text-xl">📞</span> {/* Phone Icon */}
            <span className="ml-2">+123 456 7890</span>
          </a>
        </p>

        <p>
          <a
            href="https://www.instagram.com/yourusername"
            className="flex items-center"
          >
            <span className="text-xl">📷</span> {/* Instagram Icon */}
            <span className="ml-2">Instagram</span>
          </a>
        </p>

        <p>
          <a
            href="https://www.facebook.com/yourusername"
            className="flex items-center"
          >
            <span className="text-xl">📘</span> {/* Facebook Icon */}
            <span className="ml-2">Facebook</span>
          </a>
        </p>

        <p>
          <button
            type="submit"
            onClick={handleClick}
            className="bg-violet-500 text-white py-3 px-6 mt-6 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 justify-end"
          >
            Kontaktní formulář
          </button>
        </p>
      </section>
      <section className={"p-16 pl-[20%] pr-[20%] bg-white mb-20"}></section>
    </div>
  );
};

export default NewHomePage;
