import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { AppProvider } from "./context/AppContext";
import { HashRouter, BrowserRouter as Router } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";

library.add(faInstagram, faFacebook);

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <HashRouter basename="/">
        <App />
      </HashRouter>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
