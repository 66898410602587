import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import NavigationLink from "./navigation/NavigationLink";

const MobileNavigationBar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("DOMŮ");

  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const linkClicked = (linkString: string) => {
    setSelectedTab(linkString);
    if (isOpen) setIsOpen(false);
  };

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  return (
    <div className="bg-white/80 fixed w-full top-0 z-50 shadow-lg sm:hidden">
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center justify-center w-full">
          <button
            onClick={toggleMenu}
            className="text-gray-600 hover:text-gray-800 transition duration-300 ease-in-out focus:outline-none flex items-center"
          >
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="xl" />
            <span className="text-lg text-gray-800 py-1 px-5 font-extrabold font-['MyCustomFont2']">
              {selectedTab}
            </span>
          </button>
        </div>
      </div>
      {isOpen && (
        <nav className="bg-white/10 shadow-lg">
          <ul className="flex flex-col items-center space-y-4 p-4">
            <NavigationLink
              dark
              navTo={"/newhome"}
              navString={"DOMŮ"}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={"/about"}
              navString={"O MNĚ"}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={"/theta"}
              navString={"THETA HEALING"}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={"/reservation"}
              navString={"REZERVACE"}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={"/feedback"}
              navString={"RECENZE"}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={"/contact"}
              navString={"KONTAKT"}
              navStringCall={linkClicked}
            />
          </ul>
        </nav>
      )}
    </div>
  );
};

export default MobileNavigationBar;
