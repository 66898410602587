import React, { useEffect } from "react";
import "./index.css";
import "./assets/css/glare.css";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import BlogPage from "./pages/BlogPage";
import Article from "./components/Article";
import NewHomePage from "./pages/NewHomePage";
import NewHeader from "./components/NewHeader";
import NewFooter from "./components/NewFooter";
import WorkInProgressPage from "./pages/WorkInProgressPage";
import NewFeedbackPage from "./pages/NewFeedbackPage";
import ReservationPage from "./pages/ReservationPage";
import ThetaPage from "./pages/ThetaPage";
import NewThetaPage from "./pages/NewThetaPage";

import NewAboutMePage from "./pages/NewAboutMe";
import AboutMe from "./pages/AboutMe";

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  return (
    <div className="App flex flex-col min-h-screen">
      {location.pathname.length > 1 && <NewHeader />}

      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<WorkInProgressPage />} />
          <Route path="/newhome" element={<NewHomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/about2" element={<NewAboutMePage />} />
          <Route path="/about" element={<AboutMe />} />

          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/feedback" element={<NewFeedbackPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/reservation" element={<ReservationPage />} />
          <Route path="/articles/:id" element={<Article />} />
          {/* <Route path="/theta" element={<ThetaPage />} /> */}
          <Route path="/theta" element={<NewThetaPage />} />
        </Routes>
      </main>
      <NewFooter />
    </div>
  );
};

export default App;
