import React from "react";

import NavigationLink from "./navigation/NavigationLink";

const NewNavigationBar: React.FC = () => {
  return (
    <nav className="hidden mt-3 bg-transparent p-4 rounded-lg sm:flex justify-center">
      <ul className="flex text-xs lg:text-3xl space-x-2 text-white">
        <NavigationLink navTo={"/newhome"} navString={"DOMŮ"} />
        <NavigationLink navTo={"/about"} navString={"O MNĚ"} />
        <NavigationLink navTo={"/theta"} navString={"THETA HEALING"} />
        <NavigationLink navTo={"/reservation"} navString={"REZERVACE"} />
        <NavigationLink navTo={"/feedback"} navString={"RECENZE"} />
        <NavigationLink navTo={"/contact"} navString={"KONTAKT"} />
      </ul>
    </nav>
  );
};

export default NewNavigationBar;
