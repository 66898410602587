import React from "react";
import bgImage from "../util/img/DSC_6049.jpg";

const NewAboutMePage: React.FC = () => {
  return (
    <div
      className="w-screen h-screen bg-cover bg-center flex flex-col justify-center"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="flex flex-row w-3/5 h-2/3 ml-20 justify-center align-middle items-stretch bg-white/80">
        <div className="flex-flex-col w-full h-full p-5 justify-center items-center">
          <div className="w-full h-full  overflow-y-scroll">
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">O mně</h2>
              <p>
                Jmenuji se [Vaše Jméno] a jsem vášnivý [Vaše Profese/Hobby]. S
                více než [X] lety zkušeností v oboru se zaměřuji na [vaše
                specializace nebo zájmy]. Věřím v neustálý osobní a
                profesionální růst a ráda se dělím o své znalosti a zkušenosti s
                ostatními.
              </p>
            </section>
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">Moje cesta</h2>
              <p>
                Moje cesta začala v roce [Rok], kdy jsem se rozhodl/a věnovat
                [Vaše Profese/Hobby]. Od té doby jsem prošel/a různými rolemi a
                projekty, které mi umožnily rozvíjet své dovednosti a získat
                cenné zkušenosti. Každý krok na mé cestě mě naučil něco nového a
                přinesl mi mnoho inspirace.
              </p>
            </section>
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">
                Profesionální zkušenosti
              </h2>
              <p>
                Během své kariéry jsem pracoval/a na různých projektech, které
                zahrnovaly [krátký popis projektů nebo pracovních zkušeností].
                Měl/a jsem příležitost spolupracovat s úžasnými lidmi a týmy,
                kteří mě motivovali a inspirovali. Moje práce mě baví a naplňuje
                a vždy se snažím dosáhnout nejlepších výsledků.
              </p>
            </section>
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">Osobní zájmy</h2>
              <p>
                Kromě své práce se také věnuji [vaše zájmy nebo koníčky]. Rád/a
                [krátký popis vašich zájmů], což mi pomáhá udržovat rovnováhu
                mezi pracovním a osobním životem. Tyto aktivity mi přinášejí
                radost a inspiraci a často mě vedou k novým nápadům a projektům.
              </p>
            </section>
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">Vize a cíle</h2>
              <p>
                Mou vizí je [vaše vize nebo cíl]. Snažím se neustále
                zdokonalovat a rozvíjet své dovednosti, abych mohl/a nabídnout
                svým klientům a spolupracovníkům tu nejlepší možnou podporu a
                služby. Moje cíle zahrnují [vaše konkrétní cíle], které mě
                motivují a posouvají vpřed.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-bold mb-4">Kontakt</h2>
              <p>
                Pokud máte zájem se se mnou spojit nebo se dozvědět více o mé
                práci, neváhejte mě kontaktovat. Rád/a odpovím na vaše otázky a
                proberu možnosti spolupráce. Můžete mě kontaktovat na [vaše
                kontaktní informace].
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAboutMePage;
