import React from "react";
import { NavLink, useLocation } from "react-router-dom";

interface NavigationLinkProps {
  dark?: boolean;
  navTo: string;
  navString: string;
  navStringCall?: (linkString: string) => void;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  navTo,
  navString,
  navStringCall,
  dark = false,
}) => {
  const styleBase = dark ? "text-gray-800" : " text-white ";
  const location = useLocation();
  const getLinkClasses = (path: string) =>
    location.pathname === path
      ? styleBase +
        "text-base lg:text-lg font-black shadow-ms py-4 px-5 font-['MyCustomFont2']"
      : styleBase +
        "text-base lg:text-lg font-black transition-all duration-300 ease-in-out transform hover:shadow-ms hover:text-white py-4 px-5 font-['MyCustomFont2']";

  return (
    <li
      className="text-center items-center justify-center transition-all duration-300 ease-in-out transform hover:scale-105"
      onClick={() => navStringCall && navStringCall(navString)}
    >
      <NavLink to={navTo} className={getLinkClasses(navTo)}>
        {navString}
      </NavLink>
    </li>
  );
};

export default NavigationLink;
//
