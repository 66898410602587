import React from "react";
import bgImage from "../util/img/DSC_6049.jpg";
import { useNavigate } from "react-router-dom";
import img1 from "../util/img/DSC_5901.jpg";

const HomePage: React.FC = () => {
  const sectionStyle = "p-16 pl-60 pr-60 bg-white";

  const navigate = useNavigate();

  const handleClickTheta = () => {
    navigate("/theta");
  };
  const handleClickReservation = () => {
    navigate("/reservation");
  };
  return (
    <div
      className="w-full min-h-screen bg-cover bg-center bg-fixed flex flex-col items-center justify-end"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <section className="flex flex-col w-full h-full min-h-screen items-center justify-end">
        <div className="flex flex-row w-full justify-start align-top items-start pl-40">
          <div
            className="aspect-square h-full bg-transparent text-center p-4 ml-10 cursor-pointer"
            onClick={handleClickTheta}
          >
            <div className="aspect-square h-65% bg-white/40 hover:transition-all duration-700 ease-in-out hover:translate-x-1 hover:-translate-y-1">
              <div className=" flex flex-col items-center justify-center aspect-square h-65% translate-x-6 -translate-y-6 bg-white/40 hover:transition-all duration-700 ease-in-out hover:translate-x-4 hover:-translate-y-4">
                <h3 className="text-xl p-10 pb-3 text-gray-800 font-['MyCustomFont2'] text-responsive">
                  METODA
                </h3>
                <h3 className="text-[1.6vw] p-10 pt-3 text-gray-800 font-['MyCustomFont2']">
                  THETA HEALING
                </h3>
              </div>
            </div>
          </div>

          <div
            className="aspect-square h-full bg-transparent text-center p-4 ml-20 cursor-pointer"
            onClick={handleClickReservation}
          >
            <div className="aspect-square h-95% bg-white/40 hover:transition-all duration-700 ease-in-out hover:translate-x-1 hover:-translate-y-1">
              <div className=" flex flex-col items-center justify-center aspect-square h-95% translate-x-6 -translate-y-6 bg-white/40 hover:transition-all duration-700 ease-in-out hover:translate-x-4 hover:-translate-y-4">
                <h3 className="text-xl p-8 pb-3 text-gray-800 font-['MyCustomFont2'] text-responsive">
                  PRO VÁS
                </h3>
                <h3 className="text-[1.6vw] p-8 pt-3 text-gray-800 font-['MyCustomFont2'] ">
                  TERAPIE ONLINE
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full md:w-3/5 lg:w-2/5 h-1/6 bg-white/60 mb-20 lg:mb-16 justify-center items-center text-center h-100%">
          <h1 className="cursor-default text-responsive font-800 font-['MyCustomFont3']">
            {" "}
            CESTA SVĚTLA
          </h1>
          <h1 className="cursor-default absolute text-responsive font-800  ml-1 mt-1 font-['MyCustomFont3']">
            {" "}
            CESTA SVĚTLA
          </h1>
        </div>
      </section>
      <section className="p-16 pl-60 pr-60 bg-white">
        <h2 className="text-2xl font-bold pb-4">O mně</h2>
        <p>
          Jmenuji se [Vaše Jméno] a jsem vášnivý [Vaše Profese/Hobby]. S více
          než [X] lety zkušeností v oboru se zaměřuji na [vaše specializace nebo
          zájmy]. Věřím v neustálý osobní a profesionální růst a ráda se dělím o
          své znalosti a zkušenosti s ostatními.
        </p>
      </section>
      <section className={sectionStyle}>
        <div className="flex flex-row w-full">
          <img src={img1} alt="Logo" className="h-full max-w-72" />
          <div className="flex-col pl-32">
            <h2 className="text-2xl font-bold pb-4">Moje cesta</h2>
            <p>
              Moje cesta začala v roce [Rok], kdy jsem se rozhodl/a věnovat
              [Vaše Profese/Hobby]. Od té doby jsem prošel/a různými rolemi a
              projekty, které mi umožnily rozvíjet své dovednosti a získat cenné
              zkušenosti. Každý krok na mé cestě mě naučil něco nového a přinesl
              mi mnoho inspirace.
            </p>
          </div>
        </div>
      </section>
      <section className="p-16 pl-60 pr-60 bg-white">
        <h2 className="text-2xl font-bold pb-4">O mně</h2>
        <p>
          Jmenuji se [Vaše Jméno] a jsem vášnivý [Vaše Profese/Hobby]. S více
          než [X] lety zkušeností v oboru se zaměřuji na [vaše specializace nebo
          zájmy]. Věřím v neustálý osobní a profesionální růst a ráda se dělím o
          své znalosti a zkušenosti s ostatními.
        </p>
      </section>
      <section className={sectionStyle}>
        <div className="flex flex-row w-full">
          <img src={img1} alt="Logo" className="h-full max-w-72" />
          <div className="flex-col pl-32">
            <h2 className="text-2xl font-bold pb-4">Moje cesta</h2>
            <p>
              Moje cesta začala v roce [Rok], kdy jsem se rozhodl/a věnovat
              [Vaše Profese/Hobby]. Od té doby jsem prošel/a různými rolemi a
              projekty, které mi umožnily rozvíjet své dovednosti a získat cenné
              zkušenosti. Každý krok na mé cestě mě naučil něco nového a přinesl
              mi mnoho inspirace.
            </p>
          </div>
        </div>
      </section>
      <section className="p-16 pl-60 pr-60 bg-white">
        <h2 className="text-2xl font-bold pb-4">O mně</h2>
        <p>
          Jmenuji se [Vaše Jméno] a jsem vášnivý [Vaše Profese/Hobby]. S více
          než [X] lety zkušeností v oboru se zaměřuji na [vaše specializace nebo
          zájmy]. Věřím v neustálý osobní a profesionální růst a ráda se dělím o
          své znalosti a zkušenosti s ostatními.
        </p>
      </section>
      <section className={sectionStyle}>
        <div className="flex flex-row w-full">
          <img src={img1} alt="Logo" className="h-full max-w-72" />
          <div className="flex-col pl-32">
            <h2 className="text-2xl font-bold pb-4">Moje cesta</h2>
            <p>
              Moje cesta začala v roce [Rok], kdy jsem se rozhodl/a věnovat
              [Vaše Profese/Hobby]. Od té doby jsem prošel/a různými rolemi a
              projekty, které mi umožnily rozvíjet své dovednosti a získat cenné
              zkušenosti. Každý krok na mé cestě mě naučil něco nového a přinesl
              mi mnoho inspirace.
            </p>
          </div>
        </div>
      </section>
      <section className="p-16 pl-60 pr-60 bg-white">
        <h2 className="text-2xl font-bold pb-4">Kontakt</h2>
        <p>Telefon: xxx Email: xxx</p>
      </section>
    </div>
  );
};

export default HomePage;
