import React from "react";
import bgImage from "../util/img/DSC_6049.jpg";
import CalendlyWidget from "../components/CalendifyWidget";

const ReservationPage: React.FC = () => {
  return (
    <div
      className="w-screen h-screen bg-cover bg-center flex flex-col justify-center"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="flex flex-row w-full sm:w-3/5 h-4/5 xl:h-2/3 xl:ml-20 sm:pt-12 xl:pt-0 sm:mb-4 justify-center align-middle items-stretch bg-white/80">
        <div className="flex-flex-col w-full h-full p-5 justify-center items-center align-middle">
          <h2 className="text-base sm:text-xl pb-2 text-center font-bold text-gray-800 font-['MyCustomFont2'] uppercase">
            {" "}
            Rezervace
          </h2>
          <div className="h-px w-full bg-purple-500 mb-2"></div>
          <CalendlyWidget />
        </div>
      </div>
    </div>
  );
};

export default ReservationPage;
