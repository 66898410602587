import React from "react";
import { Link } from "react-router-dom";
import { articles } from "../data/articles";

const BlogPage: React.FC = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Blog</h1>
      <ul className="space-y-4">
        {articles.map((article) => (
          <li
            key={article.id}
            className="p-4 border rounded shadow hover:shadow-lg transition-shadow duration-200"
          >
            <Link
              to={`/articles/${article.id}`}
              className="text-2xl font-bold text-blue-600 hover:underline"
            >
              {article.title}
            </Link>
            <img
              src={article.image}
              alt={article.title}
              className="mt-2 mb-2 w-full h-48 object-cover"
            />
            <p className="text-gray-700">{article.summary}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlogPage;
