import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";

interface ContactFormProps {
  show: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({ show }) => {
  const form = useRef<HTMLFormElement | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const labelStyle = "block text-xs sm:text-base font-bold text-gray-700";

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          "service_kzupgaw",
          "template_lnf6x7n",
          form.current,
          "kDkbtdbnDP5VxZQAi"
        )
        .then(
          (result) => {
            console.log(result.text);
            setStatus("Email sent successfully!");
          },
          (error) => {
            console.log(error.text);
            setStatus("Failed to send email.");
          }
        );
    }
  };

  return (
    <div className="flex flex-col w-full xl:w-3/5 sm:pt-10 p-4">
      {show ? (
        <>
          <form ref={form} onSubmit={sendEmail} className="space-y-4">
            <div>
              <label className={labelStyle}>Jméno</label>
              <input
                type="text"
                name="user_name"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md text-xs"
                required
              />
            </div>
            <div>
              <label className={labelStyle}>Email</label>
              <input
                type="email"
                name="user_email"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md text-xs"
                required
              />
            </div>
            <div>
              <label className={labelStyle}>Přemět zprávy</label>
              <input
                name="subject"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md text-xs"
                required
              />
            </div>
            <div>
              <label className={labelStyle}>Vaše zpráva</label>
              <textarea
                name="message"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md text-xs h-40 leading-relaxed"
                required
              ></textarea>
            </div>
            <div className="flex w-full justify-center sm:justify-end">
              <button
                type="submit"
                className="bg-violet-500 text-white md:text-base py-3 px-6 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 justify-end text-xs"
              >
                Odeslat zprávu
              </button>
            </div>
            {status && <p className="mt-4">{status}</p>}
          </form>
        </>
      ) : (
        <h1>Email here</h1>
      )}
    </div>
  );
};

export default ContactForm;
