import React, { useEffect, useRef } from "react";

const CalendlyWidget: React.FC = () => {
  const calendlyContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (calendlyContainerRef.current) {
      calendlyContainerRef.current.innerHTML = `
        <div
            class="calendly-inline-widget"
          data-url="https://calendly.com/hyvld/plne-sezeni"
          style="height:100%;width:100%;padding:0;margin:0"
        ></div>
      `;
    }
  }, []);

  return (
    <div
      className="w-full h-90p  p-0 bg-transparent rounded-lg"
      ref={calendlyContainerRef}
    >
      {/* The Calendly widget will be injected here */}
    </div>
  );
};

export default CalendlyWidget;
