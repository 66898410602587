import React from "react";

interface CookiesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}

const CookiesModal: React.FC<CookiesModalProps> = ({
  isOpen,
  onClose,
  onAccept,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg max-w-lg w-full">
        <h2 className="text-2xl font-bold mb-4">Používání Cookies</h2>
        <p className="mb-4">
          Naše webová stránka používá cookies pro zlepšení vašeho zážitku z
          prohlížení. Cookies jsou malé datové soubory, které jsou umístěny na
          vašem počítači nebo mobilním zařízení, když navštívíte webovou
          stránku.
        </p>
        <p className="mb-4">
          Cookies jsou široce používány vlastníky webových stránek, aby jejich
          webové stránky fungovaly, nebo aby fungovaly efektivněji, stejně jako
          poskytovaly informace o reportingu.
        </p>
        <p className="mb-4">
          Cookies nastavené vlastníkem webových stránek (v tomto případě nás) se
          nazývají "cookies první strany". Cookies nastavené jinými stranami než
          vlastníkem webových stránek se nazývají "cookies třetí strany".
          Cookies třetích stran umožňují poskytování funkcí třetích stran nebo
          funkcionalit na webových stránkách nebo prostřednictvím nich (např.
          jako je reklama, interaktivní obsah a analýzy).
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white py-2 px-4 rounded"
          >
            Zavřít
          </button>
          <button
            onClick={onAccept}
            className="bg-blue-500 text-white py-2 px-4 rounded"
          >
            Souhlasím
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesModal;
