import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

const Portfolio: React.FC = () => {
  const images = [
    "https://via.placeholder.com/300x200?text=Image+1",
    "https://via.placeholder.com/300x200?text=Image+2",
    "https://via.placeholder.com/300x200?text=Image+3",
    "https://via.placeholder.com/300x200?text=Image+4",
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="relative bg-pink-200 flex w-full p-4 z-10 transition-all duration-300 ease-in-out rotate">
        <div className="flex items-center text-white">
          <FontAwesomeIcon icon={faCoffee} size="2x" className="" />
        </div>
        <h2 className="text-white text-2xl ml-4">{"Photoshop"}</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1">
        {images.map((src, index) => (
          <div key={index} className="relative">
            <img
              src={src}
              alt={`Gallery ${index + 1}`}
              className="w-full h-auto shadow-md"
            />
          </div>
        ))}
      </div>
      <div className="relative bg-pink-200 flex w-full p-4 z-10 transition-all duration-300 ease-in-out rotate">
        <div className="flex items-center text-white">
          <FontAwesomeIcon icon={faCoffee} size="2x" className="" />
        </div>
        <h2 className="text-white text-2xl ml-4">{"Ilustrator"}</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1">
        {images.map((src, index) => (
          <div key={index} className="relative">
            <img
              src={src}
              alt={`Gallery ${index + 1}`}
              className="w-full h-auto shadow-md"
            />
          </div>
        ))}
      </div>
      <div className="relative bg-pink-200 flex w-full p-4 z-10 transition-all duration-300 ease-in-out rotate">
        <div className="flex items-center text-white">
          <FontAwesomeIcon icon={faCoffee} size="2x" className="" />
        </div>
        <h2 className="text-white text-2xl ml-4">{"InDesign"}</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1">
        {images.map((src, index) => (
          <div key={index} className="relative">
            <img
              src={src}
              alt={`Gallery ${index + 1}`}
              className="w-full h-auto shadow-md"
            />
          </div>
        ))}
      </div>
      <div className="relative bg-pink-200 flex w-full p-4 z-10 transition-all duration-300 ease-in-out rotate">
        <div className="flex items-center text-white">
          <FontAwesomeIcon icon={faCoffee} size="2x" className="" />
        </div>
        <h2 className="text-white text-2xl ml-4">{"Canva"}</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1">
        {images.map((src, index) => (
          <div key={index} className="relative">
            <img
              src={src}
              alt={`Gallery ${index + 1}`}
              className="w-full h-auto shadow-md"
            />
          </div>
        ))}
      </div>
      <div className="relative bg-pink-200 flex w-full p-4 z-10 transition-all duration-300 ease-in-out rotate">
        <div className="flex items-center text-white">
          <FontAwesomeIcon icon={faCoffee} size="2x" className="" />
        </div>
        <h2 className="text-white text-2xl ml-4">{"Figma"}</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1">
        {images.map((src, index) => (
          <div key={index} className="relative">
            <img
              src={src}
              alt={`Gallery ${index + 1}`}
              className="w-full h-auto shadow-md"
            />
          </div>
        ))}
      </div>
      <div className="flex flex-row text-lg w-full pt-10 pb-5 place-content-center">
        Pokud vás zaujalo moje portfolio neváhejte mé kontaktovat.
      </div>
      <div className="flex flex-row text-lg w-full pb-5 place-content-center">
        <button
          type="button"
          className="bg-violet-500 text-white py-3 px-6 mb-10 w-1/2 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 "
        >
          Kontakt
        </button>
      </div>
    </div>
  );
};

export default Portfolio;
