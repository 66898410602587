import React from "react";
import bgImage from "../util/img/DSC_6049.jpg";
import { useNavigate } from "react-router-dom";
import img1 from "../util/img/DSC_5901.jpg";

import img2 from "../util/img/DSC_5969.jpg";

const AboutMe: React.FC = () => {
  const navigate = useNavigate();

  const sectionStyle = "p-8 pl-[20%] pr-[20%] bg-white";

  const handleClick = () => {
    navigate("/reservation");
  };
  return (
    <div
      className="top-0 pt-24  w-full min-h-screen bg-cover bg-center bg-fixed flex flex-col justify-center"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <section className="p-8 pl-[20%] pr-[20%] pt-20 bg-white">
        <h2 className="text-2xl font-bold pb-2 uppercase">O mně</h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        <p>
          Jmenuji se [Vaše Jméno] a jsem vášnivý [Vaše Profese/Hobby]. S více
          než [X] lety zkušeností v oboru se zaměřuji na [vaše specializace nebo
          zájmy]. Věřím v neustálý osobní a profesionální růst a ráda se dělím o
          své znalosti a zkušenosti s ostatními.
        </p>
      </section>

      <section className={sectionStyle}>
        <div className="flex flex-col xl:flex-row w-full">
          <img
            src={img1}
            alt="Logo"
            className="h-full max-w-96 shadow-lg shadow-purple-600 mb-8 xl:mb-0"
            onClick={handleClick}
          />
          <div className="flex-col xl:pl-32">
            <h2 className="text-2xl font-bold pb-2 uppercase">Moje cesta</h2>
            <div className="h-px w-full bg-purple-500 mb-2"></div>

            <p>
              Moje cesta začala v roce [Rok], kdy jsem se rozhodl/a věnovat
              [Vaše Profese/Hobby]. Od té doby jsem prošel/a různými rolemi a
              projekty, které mi umožnily rozvíjet své dovednosti a získat cenné
              zkušenosti. Každý krok na mé cestě mě naučil něco nového a přinesl
              mi mnoho inspirace. Moje cesta začala v roce [Rok], kdy jsem se
              rozhodl/a věnovat [Vaše Profese/Hobby]. Od té doby jsem prošel/a
              různými rolemi a projekty, které mi umožnily rozvíjet své
              dovednosti a získat cenné zkušenosti. Každý krok na mé cestě mě
              naučil něco nového a přinesl mi mnoho inspirace.
            </p>

            <h2 className="text-2xl font-bold pb-2 uppercase pt-8">
              Moje cesta
            </h2>
            <div className="h-px w-full bg-purple-500 mb-2"></div>

            <p>
              Moje cesta začala v roce [Rok], kdy jsem se rozhodl/a věnovat
              [Vaše Profese/Hobby]. Od té doby jsem prošel/a různými rolemi a
              projekty, které mi umožnily rozvíjet své dovednosti a získat cenné
              zkušenosti. Každý krok na mé cestě mě naučil něco nového a přinesl
              mi mnoho inspirace.
            </p>
          </div>
        </div>
      </section>
      <section className={sectionStyle}>
        <h2 className="text-2xl font-bold pb-2 uppercase">
          Profesní zkušenosti
        </h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        <p>
          Během své kariéry jsem pracoval/a na různých projektech, které
          zahrnovaly [krátký popis projektů nebo pracovních zkušeností]. Měl/a
          jsem příležitost spolupracovat s úžasnými lidmi a týmy, kteří mě
          motivovali a inspirovali. Moje práce mě baví a naplňuje a vždy se
          snažím dosáhnout nejlepších výsledků.
        </p>
        <p>
          Kurzy a certifikace:
          <ul className="list-disc list-inside pl-4 mt-2">
            <li>
              Zlepšení fyzického zdraví: ThetaHealing může pomoci při léčení
              různých fyzických onemocnění a bolestí.
            </li>
            <li>
              Emocionální rovnováha: Tato technika může přinést emocionální
              uzdravení a harmonii.
            </li>
            <li>
              Duchovní růst: ThetaHealing podporuje duchovní probuzení a
              propojení s vyšším já.
            </li>
            <li>
              Osobní transformace: Praktikování ThetaHealingu může vést k
              hlubokým osobním změnám a růstu, přinášejíc pozitivní změny ve
              všech oblastech života.
            </li>
          </ul>
        </p>
      </section>
      <section className={sectionStyle}>
        <div className="flex flex-col xl:flex-row w-full">
          <div className="flex-col xl:pr-32">
            <h2 className="text-2xl font-bold pb-2 uppercase">Osobní zájmy</h2>
            <div className="h-px w-full bg-purple-500 mb-2"></div>

            <p>
              Kromě své práce se také věnuji [vaše zájmy nebo koníčky]. Rád/a
              [krátký popis vašich zájmů], což mi pomáhá udržovat rovnováhu mezi
              pracovním a osobním životem. Tyto aktivity mi přinášejí radost a
              inspiraci a často mě vedou k novým nápadům a projektům. Kromě své
              práce se také věnuji [vaše zájmy nebo koníčky]. Rád/a [krátký
              popis vašich zájmů], což mi pomáhá udržovat rovnováhu mezi
              pracovním a osobním životem. Tyto aktivity mi přinášejí radost a
              inspiraci a často mě vedou k novým nápadům a projektům.
            </p>
            <h2 className="text-2xl font-bold pb-2 uppercase pt-8">
              Vize a cíle
            </h2>
            <div className="h-px w-full bg-purple-500 mb-2"></div>

            <p>
              Mou vizí je [vaše vize nebo cíl]. Snažím se neustále zdokonalovat
              a rozvíjet své dovednosti, abych mohl/a nabídnout svým klientům a
              spolupracovníkům tu nejlepší možnou podporu a služby. Moje cíle
              zahrnují [vaše konkrétní cíle], které mě motivují a posouvají
              vpřed.
            </p>
          </div>
          <img
            src={img2}
            alt="Logo"
            className="h-full max-w-96 shadow-lg shadow-purple-600 mb-8 xl:mb-0"
            onClick={handleClick}
          />
        </div>
      </section>
      <section className={sectionStyle}>
        <h2 className="text-2xl font-bold pb-2 uppercase">Vize a cíle</h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        <p>
          Mou vizí je [vaše vize nebo cíl]. Snažím se neustále zdokonalovat a
          rozvíjet své dovednosti, abych mohl/a nabídnout svým klientům a
          spolupracovníkům tu nejlepší možnou podporu a služby. Moje cíle
          zahrnují [vaše konkrétní cíle], které mě motivují a posouvají vpřed.
        </p>
      </section>
      <section className={sectionStyle}>
        <h2 className="text-2xl font-bold pb-2 uppercase">Kontakt</h2>
        <div className="h-px w-full bg-purple-500 mb-2"></div>

        <p>
          Pokud máte zájem se se mnou spojit nebo se dozvědět více o mé práci,
          neváhejte mě kontaktovat. Rád/a odpovím na vaše otázky a proberu
          možnosti spolupráce. Můžete mě kontaktovat na [vaše kontaktní
          informace].
        </p>
      </section>
      <section className={"p-16 pl-[20%] pr-[20%] bg-white mb-20"}>
        <h2 className="text-2xl font-bold mb-8">
          Pokud tě cokoliv z toho zaujalo domluv si se mnou schůzku.
        </h2>

        <p>
          <button
            type="submit"
            onClick={handleClick}
            className="bg-violet-500 text-white py-3 px-6 pt-2 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 justify-end"
          >
            Rezervace schůzky
          </button>
        </p>
      </section>
    </div>
  );
};

export default AboutMe;
